// components/PlayerSheet.js
import React, { useState, useEffect } from "react";
import "./playersheet.css";
import playerSheetImage from "../assets/playersheet.png";

const PlayerSheet = ({ onNext }) => {
  const [name, setName] = useState("Enter Name");
  const [level, setLevel] = useState(1);
  const [gender, setGender] = useState("Male");
  const [characterClass, setCharacterClass] = useState("Barbarian");
  const [hitDie, setHitDie] = useState(1);
  const [alignment, setAlignment] = useState("True Neutral");
  const [selectedModel, setSelectedModel] = useState("gpt-3.5-turbo-16k");
  const [armorType, setArmorType] = useState("Select Armor");

  const [stats, setStats] = useState({
    Str: 10,
    Int: 10,
    Dex: 10,
    Wis: 10,
    Con: 10,
    Cha: 10,
  });

  const [statsRolled, setStatsRolled] = useState(false);

  const [inventory, setInventory] = useState("Basic Starting Items");

  const startGameEnabled = statsRolled && name !== "" && name !== "Enter Name";

  const handleStatsChange = (event, stat) => {
    const newStats = { ...stats, [stat]: parseInt(event.target.value) };
    setStats(newStats);
    if (stat === "Con") {
      setHitPoints(calculateHitPoints(characterClass, newStats.Con));
    }
  };

  const [race, setRace] = useState("");

  const races = [
    { name: "Dragonborn", traits: { Str: 2, Cha: 1 } },
    { name: "Dwarf", traits: { Con: 2 } },
    { name: "Elf", traits: { Dex: 2 } },
    { name: "Gnome", traits: { Int: 2 } },
    { name: "Half-Elf", traits: { Cha: 2 } },
    { name: "Halfling", traits: { Dex: 2 } },
    { name: "Half-Orc", traits: { Str: 2, Con: 1 } },
    {
      name: "Human",
      traits: { Str: 1, Int: 1, Dex: 1, Wis: 1, Con: 1, Cha: 1 },
    },
    { name: "Tiefling", traits: { Cha: 2, Int: 1 } },
  ];

  const alignments = [
    "Lawful Good",
    "Lawful Neutral",
    "Lawful Evil",
    "Neutral Good",
    "True Neutral",
    "Neutral Evil",
    "Chaotic Good",
    "Chaotic Neutral",
    "Chaotic Evil",
  ];

  const characterClasses = [
    "Barbarian",
    "Rogue",
    "Druid",
    "Bard",
    "Fighter",
    "Monk",
    "Paladin",
    "Ranger",
    "Sorcerer",
    "Warlock",
    "Wizard",
    "Cleric",
  ];

  const filteredCharacterClasses = characterClasses.filter((cls) => {
    if (gender === "Male" && cls === "Sorcerer") return false;
    if (gender === "Female" && cls === "Wizard") return false;
    return true;
  });

  const [remainingAttempts, setRemainingAttempts] = useState(3);

  const getHitDieForClass = (cls) => {
    switch (cls) {
      case "Barbarian":
        return 12;
      case "Bard":
      case "Cleric":
      case "Druid":
      case "Monk":
      case "Rogue":
      case "Warlock":
        return 8;
      case "Fighter":
      case "Paladin":
      case "Ranger":
        return 10;
      case "Sorcerer":
      case "Wizard":
        return 6;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setHitDie(getHitDieForClass(characterClass));
  }, [characterClass]);

  const getConstitutionModifier = (constitution) => {
    if (constitution >= 2 && constitution <= 3) return -4;
    if (constitution >= 4 && constitution <= 5) return -3;
    if (constitution >= 6 && constitution <= 7) return -2;
    if (constitution >= 8 && constitution <= 9) return -1;
    if (constitution >= 10 && constitution <= 11) return 0;
    if (constitution >= 12 && constitution <= 13) return 1;
    if (constitution >= 14 && constitution <= 15) return 2;
    if (constitution >= 16 && constitution <= 17) return 3;
    if (constitution >= 18 && constitution <= 19) return 4;
    if (constitution === 20) return 5;
  };

  const calculateHitPoints = (characterClass, constitution) => {
    const hitDieMap = {
      Barbarian: 12,
      Bard: 8,
      Cleric: 8,
      Druid: 8,
      Fighter: 10,
      Monk: 8,
      Paladin: 10,
      Ranger: 10,
      Rogue: 8,
      Sorcerer: 6,
      Warlock: 8,
      Wizard: 6,
    };

    const hitDie = hitDieMap[characterClass];
    const constitutionModifier = getConstitutionModifier(constitution);

    return hitDie + constitutionModifier;
  };

  const [hitPoints, setHitPoints] = useState(
    calculateHitPoints(characterClass, stats.Con)
  );

  const rollStat = () => {
    const rolls = Array.from({ length: 4 }, () => Math.ceil(Math.random() * 6));
    rolls.sort((a, b) => b - a);
    rolls.pop();
    return rolls.reduce((a, b) => a + b, 0);
  };

  const handleRollStats = () => {
    if (remainingAttempts > 0) {
      let newStr = rollStat();
      let newInt = rollStat();
      let newDex = rollStat();
      let newWis = rollStat();
      let newCon = rollStat();
      let newCha = rollStat();

      if (race) {
        const raceTraits = races.find((r) => r.name === race).traits;
        newStr += raceTraits.Str || 0;
        newInt += raceTraits.Int || 0;
        newDex += raceTraits.Dex || 0;
        newWis += raceTraits.Wis || 0;
        newCon += raceTraits.Con || 0;
        newCha += raceTraits.Cha || 0;
      }

      setStats({
        Str: newStr,
        Int: newInt,
        Dex: newDex,
        Wis: newWis,
        Con: newCon,
        Cha: newCha,
      });
      setHitPoints(calculateHitPoints(characterClass, newCon));
      setRemainingAttempts(remainingAttempts - 1);
      setStatsRolled(true);
    }
  };

  return (
    <div className="player-sheet-container">
      <img
        src={playerSheetImage}
        alt="Player Sheet"
        className="player-sheet-image"
      />
      <div className="player-sheet-overlay">
        <div className="row">
          <label>
            Name:{" "}
            <input
              type="text"
              value={name.toString()}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
        </div>
        <div className="row">
          <label>
            Level:
            <select
              className="select-style"
              value={level.toString()}
              onChange={(e) => setLevel(Number(e.target.value))}
            >
              <option key={1} value={1}>
                {1}
              </option>
              {Array.from({ length: 19 }, (_, i) => i + 2).map((num) => (
                <option key={num} value={num} disabled>
                  {num}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="row">
          <div className="race-container">
            <label htmlFor="race">Race:</label>
            <select
              className="select-style"
              name="race"
              value={race.toString()}
              onChange={(e) => setRace(e.target.value)}
              disabled={statsRolled}
            >
              <option value="">Select race</option>
              {races.map((race) => (
                <option key={race.name} value={race.name}>
                  {race.name}
                </option>
              ))}
            </select>
          </div>

          <label>
            Alignment:
            <select
              className="select-style"
              value={alignment.toString()}
              onChange={(e) => setAlignment(e.target.value)}
            >
              {alignments.map((align) => (
                <option key={align} value={align}>
                  {align}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="row">
          <label>
            Gender:
            <select
              className="select-style"
              value={gender.toString()}
              onChange={(e) => setGender(e.target.value)}
              disabled={statsRolled}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </label>
          <label>
            Class:
            <select
              className="select-style"
              value={characterClass.toString()}
              onChange={(e) => setCharacterClass(e.target.value)}
              disabled={statsRolled}
            >
              {filteredCharacterClasses.map((cls) => (
                <option key={cls} value={cls}>
                  {cls}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="row">
          <label>
            Hit Die: d{" "}
            <input
              type="number"
              className="stat-input"
              value={hitDie.toString()}
              onChange={(e) => setHitDie(e.target.value)}
              readOnly
            />
          </label>
          <label>
            Hit Points:{" "}
            <input
              type="number"
              className="stat-input"
              value={hitPoints.toString()}
              onChange={(e) => setHitPoints(e.target.value)}
            />
          </label>
        </div>
        <div className="stats-container">
          {["Str", "Int", "Dex", "Wis", "Con", "Cha"].map((stat, index) => (
            <label key={stat} className={`stat-${index % 2}`}>
              {stat}:{" "}
              <input
                type="number"
                className="stat-input"
                value={stats[stat].toString()}
                onChange={(e) => handleStatsChange(e, stat)}
                maxLength={2}
                readOnly
              />
            </label>
          ))}
        </div>
        {race && (
          <div className="racial-traits">
            Racial Traits:{" "}
            {Object.entries(races.find((r) => r.name === race).traits).map(
              ([key, value]) => (
                <span key={key}>
                  {key} +{value}
                </span>
              )
            )}
          </div>
        )}
        <button
          onClick={handleRollStats}
          disabled={remainingAttempts === 0 || !race}
        >
          Roll Stats
        </button>
        <span>Remaining attempts: {remainingAttempts}</span>

        <div className="inventory-armor-container">
          {" "}
          {/* Add this wrapper for inventory and armor */}
          <label>
            Inventory:
            <select
              className="select-style"
              value={inventory.toString()}
              onChange={(e) => setInventory(e.target.value)}
            >
              <option value="Basic Pack">Select Pack</option>
              <option value="Burglar's Pack">Burglar's Pack</option>
              <option value="Diplomat's Pack">Diplomat's Pack</option>
              <option value="Dungeoneer's Pack">Dungeoneer's Pack</option>
              <option value="Entertainer's Pack">Entertainer's Pack</option>
              <option value="Priest's Pack">Priest's Pack</option>
              <option value="Scholar's Pack">Scholar's Pack</option>
            </select>
          </label>
          <label>
             Armor Type:
            <select
              className="select-style"
              value={armorType}
              onChange={(e) => setArmorType(e.target.value)}
            >
              <option value="">Select Armor</option>
              <option value="None">None</option>
              <option value="Light">Light</option>
              <option value="Medium">Medium</option>
              <option value="Heavy">Heavy</option>
            </select>
          </label>
        </div>

        <button
          onClick={() =>
            onNext({
              characterData: {
                name,
                level,
                gender,
                characterClass,
                hitDie,
                hitPoints,
                stats,
                Str: stats.Str,
                Dex: stats.Dex,
                Con: stats.Con,
                Int: stats.Int,
                Wis: stats.Wis,
                Cha: stats.Cha,
                inventory,
                armorType,
                race,
                alignment,
              },
              selectedModel,
            })
          }
          disabled={!startGameEnabled}
          className={!startGameEnabled ? "button-disabled" : ""}
        >
          Start Game
        </button>

        <label htmlFor="gpt-model" className="label-style">
          GPT Model:
        </label>
        <select
          className="select-style"
          id="gpt-model"
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
        >
          <option value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</option>
          <option disabled value="gpt-4">
            gpt-4
          </option>
        </select>
      </div>
      ;
    </div>
  );
};

export default PlayerSheet;
