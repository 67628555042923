//components/chatlog.js
import React, { useContext, useRef, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ChatLogContext from "../contexts/ChatLogContext.js";
//import aiImage from "../assets/DM1.png";

function importRandomDmImage() {
  const randomNumber = Math.floor(Math.random() * 19) + 1;
  return {
    imagePromise: import(`../assets/DM${randomNumber}.png`),
    randomNumber,
  };
}

const getLast4AssistantMessages = (messages) => {
  if (!messages) {
    return [];
  }
  return messages.filter((message) => message.role === "assistant").slice(-2);
};

const getLastUserMessages = (messages) => {
  if (!messages) {
    return [];
  }
  return messages.filter((message) => message.role === "user");
};

const ChatLog = ({ playerName, gender, characterClass, race }) => {
  const { messages } = useContext(ChatLogContext);

  const [aiImage, setAiImage] = useState(null);
  const [aiImageNumber, setAiImageNumber] = useState(null);

  

  useEffect(() => {
    const { imagePromise, randomNumber } = importRandomDmImage();
    imagePromise
      .then((imageModule) => {
        setAiImage(imageModule.default);
        setAiImageNumber(randomNumber);
      })
      .catch((error) => {
        console.error("Error loading AI image:", error);
      });
  }, []);

  const imageTitles = {
    1: "Midnight Whisperer",
    2: "Shadowweaver",
    3: "Enlightened Sage",
    4: "Frothy Fiona",
    5: "Tipsy Trixie",
    6: "Alistair the Ritualist",
    7: "Malphas the Sinister",
    8: "Benedict the Mindful",
    9: "Barnaby Caskwell",
    10: "Thaddeus Silverthorn",
    11: "Zenith the Wise",
    12: "Shrouded Specter",
    13: "Aetherion the Ageless",
    14: "Sylvarith the Ancient",
    15: "King Alaric the Wise",
    16: "Moros the Bound",
    17: "Queen Octavia the Just",
    18: "Captain Blackthorn",
    19: "King Cryptus",
    // Add more titles for each image here...
  };

  const aiTitle =
    aiImageNumber && imageTitles[aiImageNumber]
      ? imageTitles[aiImageNumber]
      : "Unknown Figure";

  const userMessages = getLastUserMessages(messages);
  const aiMessages = getLast4AssistantMessages(messages);

  const userChatBoxRef = useRef();
  const aiChatBoxRef = useRef();

  const userImagePath = `${process.env.PUBLIC_URL}/assets/${gender}${race}${characterClass}.png`;

  console.log(userImagePath);
  console.log(playerName);
  console.log(aiImage);

  useEffect(() => {
    userChatBoxRef.current.scrollTop = userChatBoxRef.current.scrollHeight;
    aiChatBoxRef.current.scrollTop = aiChatBoxRef.current.scrollHeight;
  }, [messages]);

  return (
    <div>
      <div className="chat-log">
        <div className="ai-container">
          <div className="ai-image-container">
            <img src={aiImage} alt="AI Dungeon Master" className="ai-image" />
          </div>
          <div className="user-image-container">
            <img src={userImagePath} alt="User" className="user-image" />
          </div>
          <div
            className="ai-chat-box"
            ref={aiChatBoxRef}
            style={{ overflowY: "auto" }}
          >
            <h2>{aiTitle}</h2>
            {aiMessages.map((msg, index) => (
              <React.Fragment key={index}>
                <ReactMarkdown children={msg.content} />
                {index < aiMessages.length - 1 && (
                  <hr className="response-separator" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="user-container">
          <div
            className="user-chat-box"
            ref={userChatBoxRef}
            style={{ overflowY: "auto" }}
          >
            <h2>
              {playerName} the {gender} {race} {characterClass}
            </h2>
            {userMessages.map((msg, index) => (
              <p key={index}>{msg.content}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatLog;
