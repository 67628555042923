// Header.js

import React from 'react';
import './HeaderFooter.css'; 

const Header = () => {
  return (
    <header className="app-header">
      <h1>theQuest.ai</h1>
      {/* Add more elements as needed */}
    </header>
  );
};

export default Header;
