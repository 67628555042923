// src/components/WelcomeScreen.js
import React from 'react';
import backgroundImage from "../assets/Welcomebgscroll.png";

const WelcomeScreen = ({ onNext }) => {
  return (
    <div className="black-container">
      <div className="welcome-screen">
        <img
          src={backgroundImage}
          alt="Welcome Screen Background"
          className="welcome-image"
        />
        <div className="welcome-content">
          <h1>Welcome to theQuest.ai!</h1>
          <h3>
            Your AI-Powered Adventure Awaits! Embark on an unparalleled Dungeons
            & Dragons experience with our AI-driven chat-gpt Dungeon Master,
            creating a unique and personalized adventure just for you.
            <br></br>
            <br></br>
            🛡️ Craft Your Hero: Choose from iconic classes, create your
            character, and roll your stats for a truly unique experience.
            <br></br>
            <br></br>
            🐲 Embrace the Adventure: Dive into an ever-changing world where
            every choice shapes your story and destiny.
            <br></br>
            <br></br>
            🎲 Roll the Dice: Let fate and skill guide your character's journey
            with each thrilling dice roll.
            <br></br>
            <br></br>
            Join theQuest.ai now and forge your own path in a world where
            imagination knows no bounds!
          </h3>

          <button onClick={onNext}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
