//loadingscreen.js
import React from 'react';
import loadingImage from "../assets/loading.png";
import "./LoadingScreen.css"

const LoadingScreen = () => {
    return (
        <div className="loading-container">
            <div className="loading-screen">
                <img src={loadingImage} alt="Loading..." className="loading-image" />
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
};
  
export default LoadingScreen;

