//src/components/SendMessageForm.js

import React, { useContext, useState } from 'react';
import ChatLogContext from "../contexts/ChatLogContext.js";


function SendMessageForm() {
  const [input, setInput] = useState("");
  const { messages, handleMessage } = useContext(ChatLogContext);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (input.trim() === "") return;

    handleMessage({
      role: "user",
      content: input,
    });

    setInput("");

    console.log("Starting API request...");
    const startTime = Date.now();

    const response = await fetch("/.netlify/functions/api", {
   //const response = await fetch("http://localhost:3000/", {

      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ messages: [...messages, { role: "user", content: input }]}),
    });

    const endTime = Date.now();
    console.log(`API request took ${endTime - startTime}ms`);

    const data = await response.json();
    handleMessage({
      role: "assistant",
      content: data.completion.content,
    });
  };

  return (
    <form onSubmit={onSubmit} className="send-message-form">
      <div className="input-button-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit">Send</button>
      </div>
    </form>
  );
}

export default SendMessageForm;

