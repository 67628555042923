// Footer.js

import React from 'react';
import './HeaderFooter.css';

const Footer = () => {
    return (
        <div className="app-footer">
            <p>theQuest.ai, a monsterBucket application @2023</p>
            <div className="footer-button-container">
                <a href="https://www.monsterbucket.net/forum/thequest-ai" target="_blank" rel="noopener noreferrer">
                    <button>Leave Feedback</button>
                </a>
            </div>
        </div>
    );
}

export default Footer;
