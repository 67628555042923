//app.js
import React, { useState } from "react";
//import React, { useState, useEffect } from 'react';

//import GoogleSignIn from './components/GoogleSignIn.js';

import "./App.css";
import ChatLog from "./components/ChatLog.js";
import SendMessageForm from "./components/SendMessageForm.js";

import ChatLogContext from "./contexts/ChatLogContext.js";
import backgroundImage from "./assets/Background.png";

import WelcomeScreen from "./components/WelcomeScreen.js"; // Import WelcomeScreen
import PlayerSheet from "./components/PlayerSheet.js"; // Import PlayerSheet

import Header from "./components/Header.js"; 
import Footer from "./components/Footer.js";

import LoadingScreen from "./components/LoadingScreen.js";

function App() {
  const appStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const [character, setCharacter] = useState(null);

  const [loading, setLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);
  const [messages, setMessages] = useState([]);
  //const [selectedModel,setSelectedModel]= useState(null)

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleMessage = (newMessage) => {
    setMessages([...messages, newMessage]);
  };

  //useEffect(() => {
  const handleCharacterCreation = async ({
    characterData: {
      name,
      level,
      gender,
      characterClass,
      hitDie,
      hitPoints,
      stats,
      Str,
      Dex,
      Con,
      Int,
      Wis,
      Cha,
      inventory,
      armorType,
      race,
      alignment,
    },
    selectedModel,
  }) => {
    setLoading(true);
    setCharacter({
      name,
      level,
      gender,
      characterClass,
      hitDie,
      hitPoints,
      stats,
      Str,
      Dex,
      Con,
      Int,
      Wis,
      Cha,
      inventory,
      armorType,
      race,
      alignment,
    });
    //setSelectedModel(selectedModel);
    //console.log(character);

    console.log(
      level,
      name,
      characterClass,
      inventory,
      armorType,
      Str,
      Dex,
      Int,
      Con,
      Wis,
      Cha,
      gender,
      hitPoints,
      hitDie,
      race
    );
    console.log(selectedModel);

    setCurrentStep(currentStep + 1);
    const startMessage = {
      role: "user",

      //`Start Game, You Must use the following character information: ${JSON.stringify(characterData)}`,
      content:
        "Start Game, You Must use the following character information: name: " +
        name +
        " Level: " +
        level +
        " Class: " +
        characterClass +
        " Gender: " +
        gender +
        " Race: " +
        race +
        " Alignment " +
        alignment +
        " HitPoints: " +
        hitPoints +
        " HitDie: " +
        hitDie +
        "Character Stats: " +
        "Str:" +
        Str +
        "Dex:" +
        Dex +
        "Int:" +
        Int +
        "Con:" +
        Con +
        "Wis:" +
        Wis +
        "Cha:" +
        Cha +
        "Inventory:" + inventory + "Armor Class:" + armorType,

    };
    handleMessage(startMessage);
    console.log("Starting API request...");
    const startTime = Date.now();

    //const fetchData = async () => {
    //const response = await fetch("http://localhost:3000/", {
      const response = await fetch("/.netlify/functions/api", {
      
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messages: [...messages, startMessage],
       // model: "gpt-3.5-turbo"
        //stream: true,
        //model: selectedModel,
      }),
    });

    const endTime = Date.now();
    console.log(`API request took ${endTime - startTime}ms`);

    const data = await response.json();
    console.log("API response:", data);
    setLoading(false);
    if (data && data.completion && data.completion.content) {
      handleMessage({
        role: "assistant",
        content: data.completion.content,
      });
    } else {
      console.error("Unexpected API response:", data);
    }
  };

  //fetchData();

  return (
    <div className="App" style={appStyle}>
      {/* <GoogleSignIn /> */}
      <Header />
      {loading && <LoadingScreen />}
      {!loading && (
        <>
          {currentStep === 0 && <WelcomeScreen onNext={nextStep} />}
          {currentStep === 1 && (
            <PlayerSheet onNext={handleCharacterCreation} />
          )}
          {currentStep === 2 && (
            <ChatLogContext.Provider value={{ messages, handleMessage }}>
              <ChatLog
                gender={character.gender}
                characterClass={character.characterClass}
                playerName={character.name}
                race={character.race}
              />
              <SendMessageForm messages={messages} />
            </ChatLogContext.Provider>
          )}
        </>
      )}
      <Footer />
    </div>
  );
}

export default App;
